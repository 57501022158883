import * as u from 'JS/utils';

u.domReady(function() {
  // Auto-play video
  const $video_header = document.querySelector('#video_header');

  if ($video_header) {
    let video_is_playing = false;

      if ((window.innerWidth >= 768) && (video_is_playing == false))  {
        $video_header.play();
        video_is_playing = true;
      }

      if ((window.innerWidth < 768) && (video_is_playing))  {
        $video_header.pause();
        video_is_playing = false;
      }
  }
})

