import * as u from 'JS/utils'
import { styles } from './_map_styles.js'

u.domReady(function() {
  let map;
  const markers = [];
  const $location_elements = document.querySelectorAll('.location_data');

  Array.prototype.forEach.call($location_elements, function(element) {
    var location = {
      id: element.getAttribute('data-name'),
      position: {
        lat: parseFloat(element.getAttribute('data-latitude')),
        lng: parseFloat(element.getAttribute('data-longitude'))
      }
    }
    markers.push(location);
  })
  function initMap() {
    map = new google.maps.Map(document.getElementById('locations_map'), {
      styles: styles,
      center: {lat: markers[0].position.lat, lng: markers[0].position.lng},
      zoom: 9
    });
    markers.forEach(function(marker) {
      const new_marker = new google.maps.Marker({
        position: marker.position,
        map: map,
      });
    })
    Array.prototype.forEach.call($location_elements, function(element) {
      element.addEventListener('click', panToLocation)
    })
  }
  function panToLocation(e) {
    const location = new google.maps.LatLng(parseFloat(e.target.getAttribute('data-latitude')), parseFloat(e.target.getAttribute('data-longitude')))
    map.panTo(location);
  }

  window.initMap = initMap
})
