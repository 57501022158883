import * as  u from 'JS/utils'

u.domReady(function() {
  const $navigation_toggle = document.querySelector('#js_navigation_toggle');
  const $navigation = document.querySelector('.page-header-inner');

  $navigation_toggle.addEventListener('click', function(e) { openNavigation(e) });

  function openNavigation(e) {
    e.stopImmediatePropagation();

    if($navigation_toggle.classList.contains('is-active')) {
      $navigation_toggle.classList.remove('is-active');
      $navigation_toggle.setAttribute('aria-expanded', 'false');
      $navigation.classList.remove('is-active');
      $navigation_toggle.firstElementChild.innerText = "Menu"
    } else {
      $navigation_toggle.classList.add('is-active');
      $navigation_toggle.setAttribute('aria-expanded', 'true');
      $navigation.classList.add('is-active');
      $navigation_toggle.firstElementChild.innerText = "Close"
    }
  }

  // Get active state for drodown navigation item
  const $dropdowns = document.querySelectorAll('.navigation__item--with-dropdown')

  Array.prototype.forEach.call($dropdowns, function(dropdown) {
    const $feature_toggle = dropdown.querySelector('.navigation__item-link');
    const $feature_subnav = dropdown.querySelector('.navigation-subnav');

    if ($feature_subnav.querySelector('.navigation-item--active')) {
      $feature_toggle.classList.add('navigation-item--active');
    } else {
      $feature_toggle.classList.remove('navigation-item--active');
    }

    let is_focussed = false;

    $feature_toggle.addEventListener('touchend', function(e) {
      e.stopImmediatePropagation();

      if (!is_focussed) {
        $feature_toggle.focus();
        is_focussed = true;
      } if (is_focussed) {
        $feature_toggle.blur();
      }
    })
  })
})
