import * as u from 'JS/utils';

u.domReady(function() {
  const locations = document.querySelectorAll('.location')
  const addresses = document.querySelectorAll('.location_address')

  Array.prototype.forEach.call(locations, function(loc) {
    const label = loc.querySelector('.label')

    label.addEventListener('click', function(e) {
      const el = e.target
      const elID = el.dataset.location
      const address = document.getElementById(elID)

      Array.prototype.forEach.call(addresses, function(addr) {
        addr.classList.remove('active')
      })

      address.classList.add('active')
    })
  })
})
