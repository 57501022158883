import * as u from 'JS/utils';

u.domReady(function() {
  // Home page accordion
  const $accordion_toggle = document.querySelectorAll('.accordion-block-header');

  Array.prototype.forEach.call($accordion_toggle, function(el) {
    el.addEventListener('click', function(e) {
      toggleAccordion(e)
    });
  })
})

const toggleAccordion = function(e) {
  if (e.target.parentElement.classList.contains('is-active')) {
    return
  } else {
    e.target.setAttribute('aria-expanded', 'true');
    const currentTab = e.target.parentElement.parentElement.querySelector('.is-active');
    currentTab.classList.remove('is-active');
    e.target.parentElement.classList.add('is-active');

    if (window.innerWidth < 640) {
      e.target.parentElement.scrollIntoView({behaviour: "smooth"});
    }
  }
}
